<template>
  <div class="equipment">
    <PageTopNav
      :tabsShow3="true"
      @changeTab3="changeTab3"
      :tabList3="tabList3"
      :changeIndex3="changeIndex3"
    >
    </PageTopNav>
    <router-view></router-view>
    <MyNav></MyNav>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { getEquipmentGroupApi } from "@/request/api";

export default {
  components: {
    MyNav: () => import("@/components/MyNav.vue"),
    PageTopNav: () => import("@/components/PageTopNav.vue"),
  },
  data() {
    return {
      tabList3: {},
      changeIndex3: 0,
      videoCont: "",
      table_id: "",
      // 频道ID
      channel_id: "",
    };
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
    }),
  },
  created() {
    this.channel_id =
      this.$route.query.id || localStorage.getItem("equipmentId");
    this.getEquipmentGroupApi(this.$route.query.id);
  },
  // watch: {
  //   // "$route.query"(newVal) {
  //   //   this.channel_id = newVal.id;
  //   //   this.getEquipmentListApi();
  //   // },
  //   "$route.query": {
  //     handler(newVal) {
  //       this.channel_id = newVal.id;
  //       this.getEquipmentListApi();
  //     },
  //     immediate: true,
  //   },
  // },
  methods: {
    ...mapMutations("user", ["SET_EQUIPMENT_Name"]),

    async getEquipmentGroupApi(id) {
      const { code,data } = await getEquipmentGroupApi();
      if (code === 200) {
      this.tabList3 = {
        0: data[0],
        2: {
          cid: 1,
          id: 1,
          icon: "records",
          name: "数字种业",
          children: [],
        },
      };
      if (!sessionStorage.getItem("equipmentName")) {
        sessionStorage.setItem("equipmentId", data[0].id);
        sessionStorage.setItem("equipmentName", data[0].icon);
      }
      this.changeIndex3 = this.$route.query.id || this.tabList3["0"].id;
      }
      if (!id) {
        this.changeTab3({ id: "", icon: "" });
      }
    },
    changeTab3({ id, icon }) {
      if (id) {
        this.changeIndex3 = id;
      }
      sessionStorage.setItem("childTitle3", this.changeIndex3);
      sessionStorage.setItem("equipmentId", id);
      sessionStorage.setItem("equipmentName", icon);
      this.SET_EQUIPMENT_Name({ icon });

      switch (icon) {
        case "records":
          this.$router.push({
            name: "records",
          });
          break;

        case "video":
          this.$router.push({
            name: "grow",
            query: { id },
          });
          break;
        case "":
          this.$router.push({
            name: "grow",
            query: { id: this.changeIndex3 },
          });
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.equipment {
  width: 100%;
  min-height: 100vh;
  background-color: #071a2c;
  padding: 62px 0 0;
  color: #fff;
  overflow: hidden;
}
</style>
